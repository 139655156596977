import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { CardProvider } from "acciondigital-basic-theme/src/components/CartContext"
import { CartProvider } from "acciondigital-basic-theme/src/components/CartContextAPI"
import AuthProvider from "acciondigital-basic-theme/src/context/AuthContext"

import infoSitio from "../utils/infoSitio"

const { netlifySiteId: sitio } = infoSitio

const queryClient = new QueryClient()

// export const RootElement = ({ children }) => (
//   <AuthProvider>
//     <QueryClientProvider client={queryClient}>
//       <CardProvider>
//         {children}
//         {/* <ReactQueryDevtools initialIsOpen={false} /> */}
//       </CardProvider>
//     </QueryClientProvider>
//   </AuthProvider>
// )

const API_CART_URL = "/api/cart"

export const RootElement = ({ children }) => (
  <AuthProvider sitio={sitio}>
    <QueryClientProvider client={queryClient}>
      <CardProvider
        sitio={sitio}
        saveToFirebase={true}
        vigenciaDiasDefault={-1}
      >
      <CartProvider
        sitio={sitio}
        saveToFirebase={true}
        vigenciaDiasDefault={-1}
        apiUrl={API_CART_URL}
      >
        {children}
      </CartProvider>
      </CardProvider>
    </QueryClientProvider>
  </AuthProvider>
)

export default RootElement
