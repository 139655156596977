exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cat-compras-js": () => import("./../../../src/pages/cat-compras.js" /* webpackChunkName: "component---src-pages-cat-compras-js" */),
  "component---src-pages-cat-compras-jsonserver-js": () => import("./../../../src/pages/cat-compras-jsonserver.js" /* webpackChunkName: "component---src-pages-cat-compras-jsonserver-js" */),
  "component---src-pages-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-cotizacion-pedido-uuid-js" */),
  "component---src-pages-cotizaciones-js": () => import("./../../../src/pages/cotizaciones.js" /* webpackChunkName: "component---src-pages-cotizaciones-js" */),
  "component---src-pages-cotizador-truper-js": () => import("./../../../src/pages/cotizador-truper.js" /* webpackChunkName: "component---src-pages-cotizador-truper-js" */),
  "component---src-pages-cotizador-urrea-js": () => import("./../../../src/pages/cotizador-urrea.js" /* webpackChunkName: "component---src-pages-cotizador-urrea-js" */),
  "component---src-pages-dev-cart-test-api-js": () => import("./../../../src/pages/dev/cart-test-api.js" /* webpackChunkName: "component---src-pages-dev-cart-test-api-js" */),
  "component---src-pages-dev-cart-test-contextapi-js": () => import("./../../../src/pages/dev/cart-test-contextapi.js" /* webpackChunkName: "component---src-pages-dev-cart-test-contextapi-js" */),
  "component---src-pages-dev-cart-test-js": () => import("./../../../src/pages/dev/cart-test.js" /* webpackChunkName: "component---src-pages-dev-cart-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */)
}

