 import React from "react"
 import "firebase/auth"
 import "firebase/database"
 import "bootswatch/dist/journal/bootstrap.min.css"
 import './src/css/global.css'
 import { wrapPageElementQueryProvider } from "./src/utils/wrapPageElementQueryProvider"
import RootElement from "./src/components/rootElement"

//alexluong/gatsby-packages
//gatsby-plugin-use-query-params jumps to top of the page when a query param is set #33
//https://github.com/alexluong/gatsby-packages/issues/33
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
    if (location.search.includes("q=")) {
      return false;
    }
    const currentPosition = getSavedScrollPosition(location);
    return currentPosition || [0, 0];
  };

  
export const wrapPageElement = wrapPageElementQueryProvider


  // export const wrapRootElement = ({ element }) => (
  //   <CardProvider>{element}</CardProvider>
  // )

  export const wrapRootElement = ({ element }) => (
    <RootElement>{element}</RootElement>
  )